<template>
  <div class="aborigines-wrap">
    <!-- 标题区域 -->
    <div class="title-wrap">
      <span @click="language_active('english')">English</span>
      <span @click="language_active('chinese')">中文</span>
    </div>

    <!-- logo菜单区域 -->
    <div class="logo-menu-wrap">
      <img @click="menu_btn(3)" src="@/assets/icon/white.svg" alt="" />
      <img
        v-show="menuShow == 0"
        @click="menu_btn(0)"
        src="@/assets/icon/menu.png"
        alt=""
      />
      <img
        v-show="menuShow == 1"
        @click="menu_btn(1)"
        src="@/assets/icon/menu-active.png"
        alt=""
      />
    </div>
    <!-- 菜单导航区域 -->
    <menu-page class="menu-wrap" ref="enu" :flag="flag" v-show="menuShow == 1" />

    <div v-show="menuShow == 0">
      <!-- 顶部背景区域 -->
    <div class="top-bg-wrap">
      <img src="@/assets/icon/top-bg.png" alt="" />
      <img src="@/assets/icon/top-bg2.png" alt="" />
      <div :class="flag == 1 ? 'contentEnglish' : 'content'">
        <img src="@/assets/icon/down-icon.png" alt="" />
        <div>
          <span>MetaWords</span>
          <span>{{ flag == 1 ? "Inhabitant Program" : "原住民计划" }}</span>
        </div>
      </div>
    </div>
    <!-- 文字内容区域 -->
    <div class="text-wrap">
      <p style="fontFamily: NotoSansSC-Medium"  v-if="flag == 2 || flag == 0">{{ text }}</p>
      <p style="fontFamily: Montserrat-Medium" v-if="flag == 1">
        {{ textActive }} <br /><br />
        {{ textActive2 }}
      </p>
      <p style="fontFamily: NotoSansSC-Black">{{ flag == 1 ? textActive3 : text2 }}</p>
      <p style="fontFamily: Montserrat-Medium" v-if="flag == 1">{{ textActive4}} <br/> {{textActive41}}</p>
      <p style="fontFamily: NotoSansSC-Medium" v-if="flag == 2">
        {{ text3 }}<br />
        {{ text31 }}
      </p>
      <p :style="{ fontFamily: flag == 1 ? 'Montserrat-Black' : 'NotoSansSC-Black' }">{{ flag == 1 ? textActive5 : text4 }}</p>
      <p style="fontFamily: Montserrat-Medium" v-if="flag == 1">{{ textActive6 }}<br /><br />{{ textActive7 }}</p>
      <p style="fontFamily: NotoSansSC-Medium" v-if="flag == 2">{{ text5 }} <br /><br />{{ text6 }}</p>
    </div>

    <!-- 滑动区域  -->
    <div :class="flag == 1 ? 'introduce-wrap-active' : 'introduce-wrap'">
      <div class="content">
        <div class="top">
          <span>{{ flag == 1 ? "Common" : "原住民" }}</span>
          <span>{{
            flag == 1
              ? "Invite 1 new users to purchase Blind Boxes"
              : "推荐1个新用户购买盲盒解锁"
          }}</span>
        </div>
        <div class="bottom">
          <span>{{ flag == 1 ? "Title" : "身份权益" }}</span>
          <span>{{ flag == 1 ? "Common Inhabitant" : "获得原住民称号" }}</span>
          <span>{{ flag == 1 ? "Sales Reward" : "销售奖励" }}</span>
          <span  :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Medium' }">{{
            flag == 1 ? "10% Per Sale" : "每一笔获得10%销售奖励"
          }}</span>
          <img src="@/assets/aboriginesImg/aborigines-icon.png" alt="" />
        </div>
      </div>
      <div class="content">
        <div class="top">
          <span>{{ flag == 1 ? "Silver" : "白银原住民" }}</span>
          <span>{{
            flag == 1
              ? "Invite 20 new users to purchase Blind Boxes"
              : "推荐20个新用户购买盲盒解锁"
          }}</span>
        </div>
        <div class="bottom">
          <span>{{ flag == 1 ? "Title" : "身份权益" }}</span>
          <span>{{
            flag == 1 ? "Silver Inhabitant" : "获得白银原住民称号"
          }}</span>
          <span>{{ flag == 1 ? "Sales Reward" : "销售奖励" }}</span>
          <span>{{
            flag == 1 ? "15% Per Sale" : "每一笔获得15%销售奖励"
          }}</span>
          <span>{{ flag == 1 ? "Community Governing" : "社区共建" }}</span>
          <span>{{
            flag == 1 ? "Participate in Inhabitant Election" : "参加原住民选举"
          }}</span>
          <img
            class="icon2"
            src="@/assets/aboriginesImg/aborigines-icon2.png"
            alt=""
          />
        </div>
      </div>
      <div class="content">
        <div class="top">
          <span>{{ flag == 1 ? "Gold" : "黄金原住民" }} </span>
          <span>{{
            flag == 1
              ? "Invite 50 new users to purchase Blind Boxes"
              : "推荐50个新用户购买盲盒解锁"
          }}</span>
        </div>
        <div class="bottom">
          <span>{{ flag == 1 ? "Sales Reward" : "销售奖励" }}</span>
          <span>{{
            flag == 1 ? "20% Per Sale" : "每一笔获得20%销售奖励"
          }}</span>
          <span>{{ flag == 1 ? "Promotional Events" : "宣传推广" }}</span>
          <span>{{
            flag == 1
              ? "Participate in important promotional events"
              : "可参与大规模宣传活动"
          }}</span>
          <span>{{ flag == 1 ? "Original Work" : "创作权限" }}</span>
          <span>{{
            flag == 1
              ? "Propose to create 1 original MetaWords Character"
              : "可申请创作字符NFT"
          }}</span>
          <div>
            {{
              flag == 1
                ? "And all Silver Inhabitants’ benefits."
                : "及所有白银原住民的权益"
            }}
          </div>
          <img
            class="icon3"
            src="@/assets/aboriginesImg/aborigines-icon3.png"
            alt=""
          />
        </div>
      </div>
      <div class="content">
        <div class="top">
          <span>{{ flag == 1 ? "Platinum" : "白金原住民" }}</span>
          <span>{{
            flag == 1
              ? "Invite 100 new users to purchase Blind Boxes"
              : "推荐100个新用户购买盲盒解"
          }}</span>
        </div>
        <div class="bottom">
          <span>{{ flag == 1 ? "Promotional Events" : "身份权益" }}</span>
          <span>{{
            flag == 1
              ? "Propose, organize and carry out important promotional events as well as request for funding"
              : "获得白金原住民称号"
          }}</span>
          <span>{{ flag == 1 ? "Original Work" : "宣传推广" }}</span>
          <span>{{
            flag == 1
              ? "Propose to create two original MetaWords Characters"
              : "可提出、组织并实施大规模宣传活动，并申请相应经费"
          }}</span>
          <span v-if="flag == 2">{{ "创作权限" }}</span>
          <span v-if="flag == 2">{{ "可获得两次字符创作提名权" }}</span>
          <div>
            {{
              flag == 1
                ? "And all Gold Inhabitants’ benefits."
                : "及所有黄金原住民的权益"
            }}
          </div>
          <img
            class="icon4"
            src="@/assets/aboriginesImg/aborigines-icon4.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 注册按钮区域 -->
    <div class="sign-btn" @click="signBtn" :style="{ fontFamily: flag == 1 ? 'Montserrat-Medium' : 'NotoSansSC-Medium' }">
      {{ flag == 1 ? "Log In/ Register" : "现在注册" }}
    </div>
    <!-- 按钮注释区域 -->
    <div class="sign-info">
      <p :style="{ fontFamily: flag == 1 ? 'Montserrat-Medium' : 'NotoSansSC-Medium' }">{{ flag == 1 ? sifnInfoTextActive : sifnInfoText }}</p>
    </div>

    <!-- 消息区域 -->
    <div class="info-wrap" :style="{ fontFamily: flag == 1 ? 'Montserrat-Medium' : 'NotoSansSC-Medium' }">
      {{ flag == 1 ? infoTextActive : infoText }}
    </div>
    <bottom :flag="flag"/>
    </div>
  </div>
</template>

<script>
import menuPage from "@/components/menuPage.vue";
import bottom from "@/components/footer.vue";
export default {
  components: {
    menuPage,
    bottom
  },
  data() {
    return {
      flag: 2, //语言转换
      menuShow: 0, //菜单切换
      text: "为了能给参与到MetaWords建设的小伙伴们更好的福利，我们高兴地宣布正式启动MetaWords原住民计划，希望您在MetaWords美好的发展前景下，加入我们，实现共赢！",
      textActive:
        "We are pleased to announce the official launch of the MetaWords Inhabitant Program which serves as a coordinated effort to provide better resources to our community and friends who continue to support and grow with MetaWords.",
      textActive2:
        " With the promising development of MetaWords, we hope to see more of you join the community and contribute to this thriving new market!",
      text2: "成为原住民有哪些要求？",
      textActive3: "Who are we looking for? Community friends who are…",
      text3: "积极热心，理解MetaWords，看好MetaWords发展。",
      text31: "有社群推广及维护经验，可执行MetaWords的发展规划。",
      textActive4:
        "- Equally excited and passionate about MetaWords as we are and who understand the promising future that we are building for MetaWords.",
      textActive41:
        "- Experienced in community promotion and management; capable of taking on responsibility and initiative in MetaWords’ growth and development.",
      text4: "成为原住民会有哪些权益？",
      textActive5: "What benefits and rights do MetaWords Inhabitants have?",
      text5: "MetaWords共有4个原住民等级：普通、白银、黄金、和白金。",
      text6:
        " MetaWords将推行“社区自治”的理念，欢迎社区原住民提出宝贵的意见，参与社区共建，共同发展与壮大MetaWords社区！",
      textActive6:
        "There’re four levels of Inhabitants: Common, Silver, Gold, Platinum.",
      textActive7:
        " Flying Lion Lab will continue helping the MetaWords community achieve its autonomy. We would like to thank the MetaWords Inhabitants for generously providing us with your ideas and suggestions in the hopes of growing and building a better MetaWords community together.",
      sifnInfoText: "*注：原住民计划的全部功能将跳转至Lion NFT平台实现。",
      sifnInfoTextActive:
        "*Please note: You will now be redirected to the Lion NFT platform to enjoy all features of the MetaWords Inhabitant Program.",
      infoText:
        "请您尽快联系与您接触的社区元老，了解该计划的详情。活动如有调整，以MetaWords的公告为准。MetaWords拥有最终解释权。",
      infoTextActive:
        "Please get in touch with our community advisors if you are interested in the MetaWords Inhabitant Program. Events and promotions are subject to change, please follow MetaWords official announcements for updates. MetaWords reserve the right, at its sole discretion, to change, modify or cancel promotions without notice.",
    };
  },
  mounted() {
    this.$refs.enu.classNum = 2
    this.flag = Number(this.$route.query.flag)
  },
  methods: {
    // 中英文切换
    language_active(type) {
      if (type == "english") {
        this.flag = 1;
      } else {
        this.flag = 2;
      }
    },
    // 菜单按钮
    menu_btn(num) {
      if (num == 0) {
        this.menuShow = 1;
      } else if (num == 1) {
        this.menuShow = 0;
      }else if(num == 3) {
        this.$router.push('/')
      }
    },
		// 注册按钮
		signBtn() {
			// window.open("https://lionnft.net/symbols?dsid=1");
		},
    
  },
};
</script>

<style lang="scss" scoped>
// 标题区域样式
.title-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 22px;
  height: 30px;
  background: #000000;
  span {
    width: 89px;
    text-align: right;
    font-size: 18px;
    font-family: Montserrat-Regular;
    font-weight: 400;
    color: #ffffff;
  }
}
// logo 菜单区域样式
.logo-menu-wrap {
  position: absolute;
  top: 30px;
  left: -5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 22px;
  width: 100%;
  z-index: 999;
  box-sizing: border-box;
  img:nth-child(1) {
    width: 53px;
    height: 53px;
  }
  img:nth-child(2) {
    width: 42px;
    height: 42px;
  }
  img:nth-child(3) {
    width: 42px;
    height: 42px;
  }
  img:nth-child(2):hover {
    background: url("../assets/icon/menu-active.png") no-repeat 42px 42px;
  }
}
// 菜单区域样式
.menu-wrap {
  margin-top: 120px;
}
// 顶部背景区域样式
.top-bg-wrap {
  position: absolute;
  top: 30px;
  img:nth-child(1) {
    width: 100%;
    height: 253px;
  }
  img:nth-child(2) {
    position: absolute;
    top: 45px;
    left: 30px;
    width: 356px;
    height: 188px;
    z-index: 99;
  }
  .contentEnglish {
    position: absolute;
    top: 85px;
    left: 45px;
    display: flex;
    align-items: top;
    z-index: 999;
    img {
      width: 25px;
      height: 26px;
    }
    span {
      display: block;
      margin: 0px 0 0 14px;
      width: 263px;
      font-size: 24px;
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      color: #000000;
      line-height: 32px;
    }
  }
  .content {
    position: absolute;
    top: 85px;
    left: 65px;
    display: flex;
    align-items: top;
    z-index: 999;
    img {
      width: 25px;
      height: 26px;
    }
    span {
      display: block;
      margin: 0px 0 0 14px;
      font-size: 26px;
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      color: #000000;
      line-height: 32px;
    }
  }
}

// 文字内容区域样式
.text-wrap {
  margin-top: 293px;
  padding: 0 22px 0 25px;
  p {
    width: 367px;
    font-size: 21px;
    color: #231916;
    line-height: 30px;
    font-weight: 500;
  }
  p:nth-child(2),
  p:nth-child(4) {
    font-weight: 900;
  }
}

// 滑动区域
.introduce-wrap {
  display: flex;
  padding-left: 25px;
  height: 495px;
  overflow-x: scroll;
  .content {
    margin-right: 20px;
    span {
      display: block;
    }
    .top {
      height: 159px;
      width: 318px;
      background: #181726;
      border: 1px solid #181726;
      span {
        width: 268px;
        font-size: 21px;
        font-family: NotoSansSC-Bold, NotoSansSC;
        font-weight: bold;
        color: #ffffff;
      }
      span:nth-child(1) {
        margin: 60px 0 0 25px;
      }
      span:nth-child(2) {
        margin: 20px 0 0 25px;
        font-size: 18px;
        font-weight: 400;
      }
    }
    .bottom {
      position: relative;
      width: 318px;
      height: 320px;
      background: #ffffff;
      border: 1px solid #181726;
      span {
        margin-left: 25px;
        width: 268px;
        font-size: 18px;
        font-family: NotoSansSC-Medium, NotoSansSC;
        font-weight: 500;
        color: #231916;
        line-height: 26px;
      }
      span:nth-child(1),
      span:nth-child(3),
      span:nth-child(5) {
        margin-top: 25px;
      }
      span:nth-child(2),
      span:nth-child(4),
      span:nth-child(6) {
        color: #8b8b8b;
      }
      div {
        position: absolute;
        left: 25px;
        bottom: 10px;
        font-size: 15px;
        color: #8b8b8b;
      }
      img {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 90px;
        width: 95px;
      }
      .icon2 {
        width: 115px;
        height: 116px;
      }
      .icon3 {
        width: 113px;
        height: 140px;
      }
      .icon4 {
        width: 71px;
        height: 116px;
      }
    }
  }
}
.introduce-wrap-active {
  display: flex;
  padding-left: 25px;
  height: 495px;
  overflow-x: scroll;
  .content {
    margin-right: 20px;
    span {
      display: block;
    }
    .top {
      height: 159px;
      width: 318px;
      background: #181726;
      border: 1px solid #181726;
      span {
        width: 268px;
        font-size: 21px;
        font-family: Montserrat-Bold;
        font-weight: bold;
        color: #ffffff;
      }
      span:nth-child(1) {
        margin: 60px 0 0 25px;
      }
      span:nth-child(2) {
        margin: 20px 0 0 25px;
        font-size: 18px;
        font-weight: 400;
      }
    }
    .bottom {
      position: relative;
      width: 318px;
      height: 320px;
      background: #ffffff;
      border: 1px solid #181726;
      span {
        margin-left: 25px;
        width: 268px;
        font-size: 18px;
        font-family: Montserrat-Medium;
        font-weight: 500;
        color: #231916;
        line-height: 26px;
      }
      span:nth-child(1),
      span:nth-child(3),
      span:nth-child(5) {
        margin-top: 25px;
      }
      span:nth-child(2),
      span:nth-child(4),
      span:nth-child(6) {
        color: #8b8b8b;
        z-index: 9999;
      }
      span:nth-child(6) {
        position: relative;
      }
      div {
        position: absolute;
        left: 25px;
        bottom: 10px;
        font-size: 15px;
        color: #8b8b8b;
        z-index: 999;
      }
      img {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 90px;
        width: 95px;
        z-index: 99;
      }
      .icon2 {
        width: 115px;
        height: 116px;
      }
      .icon3 {
        width: 113px;
        height: 140px;
      }
      .icon4 {
        width: 71px;
        height: 116px;
      }
    }
  }
}

// 注册按钮区域样式
.sign-btn {
  margin: 50px auto 0;
  width: 311px;
  height: 61px;
  text-align: center;
  line-height: 61px;
  background: #181726;
  border-radius: 6px;
  font-size: 21px;
  font-weight: 500;
  color: #ffffff;
}
.sign-info {
  margin: 9px auto 0;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
  p {
    font-size: 16px;
    font-weight: 500;
    color: #585858;
    line-height: 24px;
  }
}

// 消息区域
.info-wrap {
  margin: 34px 18px 57px 25px;
  font-size: 14px;
  font-weight: 500;
  color: #585858;
  line-height: 20px;
}

</style>